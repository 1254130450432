.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.select-header .select-header_1 {
  color: #8492a6;
  float: left;
  font-size: 13px;
  margin-left: 50px;
  width: 140px;
}
.select-header .select-header_1:first-child {
  width: 100px;
  margin-left: 0;
}
.select-header .select-header_2 {
  color: #8492a6;
  float: left;
  font-size: 13px;
}
.select-header .select-header_2:first-child {
  width: 150px;
}
.select-header .select-header_2:last-child {
  width: 250px;
  margin-left: 50px;
}
